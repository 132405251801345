import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import ReactHtmlParser from 'react-html-parser';
import ComponentStyles from '../brand-styles/pages/faq-categories.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

import AccordionComponent from '../components/AccordionComponent';
import PagesHero from '../components/PagesHero';
import ContactBox from '../components/ContactBox';
import { breakpoints, colors } from '../style-utilities/variables';

const Div = styled.div`
  .hero-section {
    background: ${props => `url(${props.bgImage}) no-repeat top center`};
    background-size: cover;
  }
  .Hero {
    display: flex;
    align-items: center;
    height: 400px;
  }
  .Hero h1 {
    margin: auto;
  }
  .main-section {
    padding: 40px;
    display: grid;
    grid-template-columns: 70% 30%;
    .accordion .title {
      color: ${colors.bodyText};
    }
    .Contact {
      text-align: center;
    }
    .Contact h2 {
      line-height: 1.4;
    }
    #icon,
    h3 {
      margin: 0 10px;
    }
    #icon {
      transform: scaleX(-1);
      margin-bottom: 16px;
    }
    @media (max-width: ${breakpoints.tablet}) {
      display: block;
      padding: 50px 0 0;
      .Contact {
        margin-top: 40px;
      }
    }
  }
  /* Dynamic brand styles */
  ${ComponentStyles}
`;

class Faqs extends Component {
  state = {
    activeAcc: 1
  };

  accordionChangeHandler = clickedAcc => {
    this.setState({ activeAcc: clickedAcc });
  };

  render() {
    const {
      name,
      slug,
      wordpress_id: category_id,
      yoast_head_json: { title, description }
    } = this.props.data.wordpressWpFaqCategory;
    const { edges: faqs } = this.props.data.allWordpressWpFaq;
    const heroImageUrl = this.props.data.wordpressPage.acf.hero_image
      ? this.props.data.wordpressPage.acf.hero_image.source_url
      : '';
    return (
      <Div bgImage={heroImageUrl}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <PagesHero className="hero-section">
          <div className="Hero">
            <div className="InnerBox">
              <div className="title">
                <h1>FAQ - {name}</h1>
              </div>
            </div>
          </div>
        </PagesHero>
        <div className="main-section">
          <div>
            {faqs
              .filter(({ node }) => node.faq_category.includes(category_id))
              .map(({ node }) => {
                return (
                  <AccordionComponent
                    key={node.wordpress_id}
                    clicked={() =>
                      this.accordionChangeHandler(node.wordpress_id)
                    }
                    accState={this.state.activeAcc === node.wordpress_id}
                    id={node.wordpress_id}
                    title={ReactHtmlParser(node.title)}
                    content={ReactHtmlParser(node.content)}
                  />
                );
              })}
          </div>

          <div className="Contact">
            <ContactBox>
              <h2>Have more questions or concerns?</h2>
              <FontAwesomeIcon id="icon" icon={faPhone} />
              <br />
              <h3>800.730.5957</h3>
              <p class="contact-time">9&nbsp;AM &ndash; 5&nbsp;PM EST</p>
              <span>Monday &ndash; Friday</span>
            </ContactBox>
          </div>
        </div>
      </Div>
    );
  }
}

export default Faqs;

export const query = graphql`
  query($slug: String!) {
    allWordpressWpFaq {
      edges {
        node {
          wordpress_id
          title
          content
          faq_category
        }
      }
    }
    wordpressWpFaqCategory(slug: { eq: $slug }) {
      wordpress_id
      name
      slug
      description
      yoast_head_json {
        title
        description
      }
    }
    wordpressPage(slug: { eq: "faq" }) {
      acf {
        hero_image {
          source_url
        }
      }
    }
  }
`;
